import React from "react";
import DataTable from "../../../../components/DataTable";
// import { useTopupHistoryQuery } from "../../../../Services/topupApi";

const AllLevelTopupHistoryByAdminTable = ({
  data,
  totalData,
  totalItems,
  pageNo,
  date,
  pagePerShow,
  setPagePerShow,
  setPageNo,
  isCustomPagination,
}) => {
  const columns = [
    { id: "sr", label: "Sr.", minWidth: 20 },
    { id: "user_id", label: "User ID", minWidth: 150 },
    // { id: "level", label: "Level", minWidth: 20 },
    {
      id: "packages",
      label: "Upgraded Package",
      minWidth: 150,
    },
    {
      id: "upgradedAmount",
      label: "Upgraded Amount",
      minWidth: 150,
    },
    {
      id: "packageType",
      label: "Package Type",
      minWidth: 150,
    },
    {
      id: "date",
      label: "Topup Date",
      minWidth: 120,
    },
    {
      id: "status",
      label: "Status",
      minWidth: 80,
    },
  ];

  function createData(
    sr,
    user_id,
    // level,
    packages,
    upgradedAmount,
    packageType,
    date,
    status
  ) {
    return {
      sr,
      user_id,
      // level,
      packages,
      upgradedAmount,
      packageType,
      date,
      status,
    };
  }

  const rows = data?.map((d, i) =>
    createData(
      (totalItems?.page - 1) * pagePerShow + (i + 1),
      d?.userId,
      // d?.level,
      d?.upgradedAmount ? "$" + d?.packageInfo.amount : "N/A",
      d?.upgradedAmount
        ? "$" + d?.upgradedAmount
        : `- $${d?.packageInfo.amount}`,
      d?.packageType,
      d?.packageInfo?.date,
      <span
        style={{
          borderRadius: "50px",
          padding: "5px 8px",
          fontSize: "13px",
          textTransform: "capitalize",
          backgroundColor: "rgba(28,213,174,.2)",
          color: "#38cab3",
        }}
      >
        success
      </span>
    )
  );

  // console.log(data);
  return (
    <DataTable
      columns={columns}
      rows={rows}
      perPageShow={pagePerShow}
      tableHeight={640}
      className="common_table"
      data={totalItems}
      date={date}
      totalData={totalData}
      pageNo={pageNo}
      setPageNo={setPageNo}
      setPagePerShow={setPagePerShow}
      pagePerShow={pagePerShow}
      isCustomPagination={isCustomPagination}
    />
  );
};

export default AllLevelTopupHistoryByAdminTable;

import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { DateRangePicker } from "react-date-range";
import { useGetLoginUserQuery } from "../../Services/userApi";
import { userRole } from "../../config/USER_ROLE";
import Button from "../Button";
import CardLayout from "../CardLayout";
import LevelTeamFilter from "../Filter/LevelTeamFilter";
import Input from "../Input";
import Modal from "../Modal";

const SectionCommonTable = ({
  wrapperClassName,
  cardStyle,
  sectionTableTitle,
  countContainer,
  table,
  calculateCredit,
  calculateDebit,
  calculateContainer,
  data,
  setCSV,
  csvData,
  toDay,
  lastDay,
  firstDay,
  setDate,
  setSearchById,
  searchInputText,
  addGiftAllUser,
  level,
  levelFilter,
  filterFromUser,
  setLevel,
  sendAll,
  adminBalance,
  changeAllStatus,
  changeAllStatusReject,
  modalRef,
}) => {
  const [showModal, setShowModal] = useState(false);
  const { data: loginUserData } = useGetLoginUserQuery();

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // download data
  const getHeaders = () => {
    if (csvData?.length > 0) {
      return Object?.keys(csvData[0])?.map((key) => ({
        label: key?.toUpperCase(),
        key: key,
      }));
    }
    // if (dataSwap?.length > 0) {
    //   return Object?.keys(dataSwap[0])?.map((key) => ({
    //     label: key?.toUpperCase(),
    //     key: key,
    //   }));
    // }
    return [];
  };

  const headers = getHeaders();

  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className={`ss-trade_sectiontable_wrapper ${wrapperClassName}`}>
      {adminBalance && (
        <CardLayout className="admin__balance__area">
          <div className="admin__balance">
            <h2 className="usdt_balance">
              USDT Balance:{" "}
              {adminBalance?.usdtBalance
                ? parseFloat(adminBalance?.usdtBalance).toFixed(4)
                : 0}{" "}
              USDT
            </h2>
            <h2>
              BNB Balance:{" "}
              {adminBalance?.bnbBalance
                ? parseFloat(adminBalance?.bnbBalance).toFixed(4)
                : 0}{" "}
              BNB
            </h2>
          </div>
        </CardLayout>
      )}

      <CardLayout style={cardStyle} className="ss-trade_sectiontable_card">
        <div className="ss-trade_sectiontable_title">
          <div className="ss-trade_sectiontable_title_container">
            <h2>{sectionTableTitle}</h2>
            {addGiftAllUser && (
              <Button
                className="ss-trade_giftAllButton"
                type="button"
                onClick={() => addGiftAllUser()}
                // hidden={lastDay}
                disabled={!(toDay === lastDay || toDay === firstDay)}
                style={{
                  display:
                    toDay === lastDay || toDay === firstDay
                      ? "inline-block"
                      : "none",
                }}
              >
                Gift Send To All
              </Button>
            )}
          </div>
          <div className="left">
            {sendAll && (
              <Button onClick={sendAll} className="send-all">
                Send All
              </Button>
            )}
            {changeAllStatus && (
              <>
                <Button onClick={changeAllStatus} className="success-all">
                  success
                </Button>
                <Button onClick={changeAllStatusReject} className="reject-all">
                  Reject
                </Button>
              </>
            )}

            {countContainer && (
              <div style={{ marginRight: "5px" }} className="searchbar_input">
                ${countContainer}
              </div>
            )}
            {loginUserData?.data?.role === userRole.ADMIN &&
              data &&
              (csvData?.length > 0 ? (
                <CSVLink
                  style={{ marginLeft: "5px" }}
                  className="downloadCSV_button"
                  data={csvData?.length > 0 ? csvData : data}
                  headers={headers}
                  filename={"sst.csv"}
                >
                  <Button
                    className="downloadCSV_button"
                    onClick={() => {
                      setCSV("");
                      setIsLoading(false);
                    }}
                  >
                    Download Data
                  </Button>
                </CSVLink>
              ) : (
                <Button
                  style={{ marginLeft: "10px" }}
                  className="downloadCSV_button"
                  onClick={() => {
                    setCSV("csv");
                    setIsLoading(true);
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Load Data"}
                </Button>
              ))}
            {setSearchById && (
              <div className="searchbar_input">
                <Input
                  type="search"
                  name="search"
                  className="spacial_search_input"
                  placeholder={
                    searchInputText ? searchInputText : "Search user id"
                  }
                  onChange={(e) => {
                    setSearchById(e.target.value);
                    // setCSV("csv");
                  }}
                />
              </div>
            )}

            {filterFromUser && setDate ? (
              <Button
                className="filter_button"
                onClick={() => setShowModal(true)}
              >
                Filter
              </Button>
            ) : (
              loginUserData?.data?.role === userRole.ADMIN &&
              setDate && (
                <Button
                  className="filter_button"
                  onClick={() => setShowModal(true)}
                >
                  Filter
                </Button>
              )
            )}
            {filterFromUser && setDate ? (
              <Button
                className="filter_button"
                onClick={() => {
                  setDate("");
                  setSearchById("");
                  setCSV("");
                  setIsLoading(false);
                }}
              >
                Reset
              </Button>
            ) : (
              loginUserData?.data?.role === userRole.ADMIN &&
              setDate && (
                <Button
                  className="filter_button"
                  onClick={() => {
                    setDate("");
                    setSearchById("");
                    setCSV("");
                    setIsLoading(false);
                  }}
                >
                  Reset
                </Button>
              )
            )}
          </div>
        </div>
        {levelFilter && <LevelTeamFilter level={level} setLevel={setLevel} />}
        <div className="ss-trade_sectiontable_table">{table}</div>
        {calculateContainer && (
          <div className="ss-trade_sectiontable_calculate">
            {calculateCredit && (
              <h2 className="credit_balance">{calculateCredit}</h2>
            )}
            {calculateDebit && (
              <h2 className="debit_balance">{calculateDebit}</h2>
            )}
          </div>
        )}
      </CardLayout>

      {showModal && (
        <Modal
          modalRef={modalRef}
          setOpenModal={setShowModal}
          openModal={showModal}
        >
          <DateRangePicker
            onChange={(item) => {
              setState([item.selection]);
              setDate([
                new Date(item.selection.startDate).toLocaleDateString(),
                new Date(item.selection.endDate).toLocaleDateString(),
              ]);
              // setCSV("csv");
            }}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            // months={2}
            ranges={state}
            direction="horizontal"
          />
          {/* <Calendar
            date={new Date()}
            onChange={(item) => {
              setDate(new Date(item).toLocaleDateString());
            }}
          /> */}
        </Modal>
      )}
    </div>
  );
};

export default SectionCommonTable;

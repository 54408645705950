import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useGetMemberUpgradeBusinessHistoryQuery } from "../../../Services/userApi";
import Loading from "../../../components/Loading/Loading";
import SectionCommonTable from "../../../components/SectionCommonTable";
import AllLevelTopupHistoryByAdminTable from "./table/allLevelUpgradeTopupHistoryTable";

const AllLevelUpgradeTopUpHistoryPage = () => {
  const location = useLocation();
  const params = useParams();
  const page = parseInt(location.search?.split("=")[1]) || 1;
  const [pageNo, setPageNo] = useState(page);
  const [searchById, setSearchById] = useState("");
  const [date, setDate] = useState([]);
  const [pagePerShow, setPagePerShow] = useState(10);
  const [csv, setCSV] = useState("");

  const filterQuery = {
    page: pageNo,
    limit: pagePerShow,
    level: params?.level,
    userId: params?.userid,
    searchById,
    startDate: new Date(date[0]).toLocaleDateString() || "",
    endDate: new Date(date[1]).toLocaleDateString() || "",
    csv,
  };

  const { data, isLoading } =
    useGetMemberUpgradeBusinessHistoryQuery(filterQuery);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      <SectionCommonTable
        wrapperClassName="topuphistory_table"
        cardStyle={{ backgroundColor: "#fff" }}
        sectionTableTitle={`All Level Upgrade Business History (${
          data?.data?.totalDocs || 0
        })`}
        data={data?.data?.docs}
        countContainer={data?.data?.totalAmount}
        setDate={setDate}
        setSearchById={setSearchById}
        searchById={searchById}
        date={date}
        setCSV={setCSV}
        csvData={data?.csv}
        table={
          <AllLevelTopupHistoryByAdminTable
            data={data?.data?.docs}
            totalItems={data?.data}
            totalData={data?.data?.totalDocs}
            searchId={searchById}
            date={date}
            pageNo={pageNo}
            pagePerShow={pagePerShow}
            setPageNo={setPageNo}
            setPagePerShow={setPagePerShow}
            isCustomPagination={true}
          />
        }
      />
    </>
  );
};

export default AllLevelUpgradeTopUpHistoryPage;

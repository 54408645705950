export const env = {
  SECRET_KEY:
    "71e1518c1cc9c5ffca9f68bb813b8fbf9d90008be91da2fc2f830d42b61633d887ec05cadbdbc9ec9ee168cf4b7b3846c7619f027db5c5b1339e2105826ba465",
  // BASE_URL: "http://localhost:3333",
  BASE_URL: "https://api.safeandsecuretrade.com",
  // BASE_URL: "https://development.api.safeandsecuretrade.com",
  // BASE_URL: "https://safeandsecuretrade.triplewsols.in",
  // BASE_URL: "https://development.safeandsecuretrade.triplewsols.in",
  // BASE_URL: "https://sast2.vercel.app", // vercel URL
  // BASE_URL: "https://safeandsecuretrade-backend.vercel.app", // vercel URL
};

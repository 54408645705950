import React from "react";
import "./LevelTeamFilter.scss";

const LevelTeamFilter = ({ level, setLevel }) => {
  const handleClick = (n) => {
    if (level === n) {
      setLevel("all-level");
    } else {
      setLevel(n);
    }
  };

  var levelCount = [1, 2, 3, 4, 5, 6, 7];

  return (
    <>
      <div className="search_by_button">
        {levelCount?.map((levelNo) => (
          <button
            className={`btn green ${level === levelNo ? "active-btn" : ""}`}
            onClick={() => handleClick(levelNo)}
            key={levelNo}
          >
            level: {levelNo}
          </button>
        ))}
      </div>
    </>
  );
};

export default LevelTeamFilter;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  // removeLocalStorage,
  savedLocalStorage,
  getLocalStorage,
} from "./../../../utils/function/localStorage";
import {
  useAddLoginMutation,
  // useSendOtpAdminMutation,
} from "../../../Services/userApi";
import { loginValidate } from "../../../components/Validation/vaildate";
import { Notification } from "../../../components/ToastNotification";
import { FaEyeSlash, FaEye } from "react-icons/fa";
const Home = () => {
  const [errors, setErrors] = useState({}); // error catch
  const navigate = useNavigate();
  const [value, setValue] = useState({
    userId: "",
    password: "",
    // otpCode: "",
  });
  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  // auth check
  useEffect(() => {
    if (getLocalStorage("safe_secure_token")) {
      navigate("/dashboard");
    }
  }, [navigate]);

  // error
  useEffect(() => {
    setErrors(loginValidate(value));
  }, [value]);

  // add user
  const [addLogin, { error, data, isLoading }] = useAddLoginMutation();
  useEffect(() => {
    if (data?.message) {
      Notification(data?.message, "success");
      navigate("/dashboard");
      savedLocalStorage("safe_secure_token", data?.token);
    } else {
      Notification(error?.data?.message, "error");
    }
  }, [error, data, navigate]);

  // // Send OTP
  // const [showOtp, setShowOtp] = useState(false);
  // const [sendOtp, { data: otpData, error: otpError }] =
  //   useSendOtpAdminMutation();

  // useEffect(() => {
  //   if (otpData) {
  //     Notification(otpData?.message, "success");
  //     setShowOtp(true);
  //   } else if (otpError) {
  //     Notification(otpError?.data?.message, "error");
  //     setShowOtp(false);
  //   }
  // }, [otpData, otpError]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.keys(errors).length > 0) {
      Notification(errors?.userId || errors?.password, "error");
    } else {
      if (value?.userId?.toLowerCase() !== "admin") {
        Notification("Only Admin login allow", "error");
      } else {
        const logData = {
          ...value,
          userId: value.userId.toLowerCase(),
        };
        await addLogin(logData);
      }
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const token = getLocalStorage("safe_secure_token");
  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token, navigate]);
  return (
    <>
      <div className="sst__admin__landingPage__wrapper">
        <div className="sst__admin__landingPage__main">
          <div className="sst__admin__landingPage__container">
            <div className="sst__admin__login__form">
              <h2>Admin Login</h2>
              <form onSubmit={handleSubmit}>
                <div className="sst__admin__form__group">
                  <div className="form__group">
                    <label htmlFor="userId">User ID</label>
                    <input
                      type="text"
                      name="userId"
                      placeholder="Enter your user id"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form__group">
                    <label htmlFor="password">Password</label>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Enter your password"
                      onChange={handleChange}
                    />
                    <span
                      className="passwordShow"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </span>
                  </div>
                  {/* {showOtp && (
                    <div className="form__group">
                      <label htmlFor="otp">OTP</label>
                      <input
                        type="number"
                        name="otpCode"
                        placeholder="Enter your OTP"
                        onChange={handleChange}
                      />
                    </div>
                  )} */}

                  <div className="form__group">
                    <button type="submit" disabled={isLoading}>
                      {isLoading ? "Loading..." : "Login"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

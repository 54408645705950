import React from "react";
import AllLevelTopupHistoryByAdminPage from "../containers/AdminPages/AdminMemberPage/Topup.AllLevelTopupHistoryPage";
import AllLevelUpgradeTopUpHistoryPage from "../containers/AdminPages/AdminMemberPage/Topup.AllLevelUpgradeTopUpHistoryPage";
import Page404 from "../containers/Page404NotFound/Page404";

const AdminAllThisMonthTeamBusinessHistory = React.lazy(() =>
  import(
    "../containers/AdminPages/AdminMemberPage/Topup.AllThisMonthTeamBusinessHistory"
  )
);

const TopupHistoryByAdminPage = React.lazy(() =>
  import("../containers/AdminPages/AdminMemberPage/Topup.TopupHistoryPage")
);
const UpgradeTopUpHistory = React.lazy(() =>
  import("../containers/AdminPages/AdminMemberPage/Topup.UpgradeTopUpHistory")
);
const AdminTopupHistoryPage = React.lazy(() =>
  import("../containers/AdminPages/Admin.Topup/Admin.TopupHistoryPage")
);
const ManageFunction = React.lazy(() =>
  import("../containers/AdminPages/ManageFunction/ManageFunction")
);
const AdminDashboard = React.lazy(() =>
  import("../containers/AdminPages/AdminHome/AdminHome")
);

// member section
const AddressHistory = React.lazy(() =>
  import("../containers/AdminPages/AdminMemberPage/Admin.AddressHistory")
);
const AllMember = React.lazy(() =>
  import("../containers/AdminPages/AdminMemberPage/Admin.AllMember")
);
const ActiveMember = React.lazy(() =>
  import("../containers/AdminPages/AdminMemberPage/Admin.ActiveMember")
);
const BlockedMember = React.lazy(() =>
  import("../containers/AdminPages/AdminMemberPage/Admin.BlockedMember")
);
const UserPIN = React.lazy(() =>
  import("../containers/AdminPages/AdminMemberPage/Admin.AllUserPin")
);
const AdminTeamStatistics = React.lazy(() =>
  import("../containers/AdminPages/AdminMemberPage/Admin.TeamStatistics")
);

// deposit section
const AllDeposit = React.lazy(() =>
  import("../containers/AdminPages/AdminDepositReport/Admin.AllTransaction")
);
const SuccessfulDeposit = React.lazy(() =>
  import(
    "../containers/AdminPages/AdminDepositReport/Admin.SuccessfulTransaction"
  )
);
const RejectedDeposit = React.lazy(() =>
  import(
    "../containers/AdminPages/AdminDepositReport/Admin.RejectedTransaction"
  )
);
// manage Deposit history
const AdminManageDeposit = React.lazy(() =>
  import("../containers/AdminPages/AdminManageDeposit/Admin.ManageDeposit")
);
const ManageDeposit = React.lazy(() =>
  import("../containers/AdminPages/AdminManageDeposit/AdminLogin")
);
const AdminManageDepositHistory = React.lazy(() =>
  import(
    "../containers/AdminPages/AdminManageDeposit/Admin.ManageDepositHistory"
  )
);
const ManageDepositHistory = React.lazy(() =>
  import("../containers/AdminPages/AdminManageDeposit/AdminLogin")
);

// withdraw section
const AllWithdraw = React.lazy(() =>
  import("../containers/AdminPages/AdminWithdrawReport/Admin.AllWithdraw")
);
const SuccessfulWithdraw = React.lazy(() =>
  import(
    "../containers/AdminPages/AdminWithdrawReport/Admin.SuccessfulWithdraw"
  )
);
const RejectedWithdraw = React.lazy(() =>
  import("../containers/AdminPages/AdminWithdrawReport/Admin.RejectedWithdraw")
);

// admin earnings
const AdminDirectIncome = React.lazy(() =>
  import("../containers/AdminPages/AdminEarningTrack/AdminEarning.DirectIncome")
);
const AdminLevelIncome = React.lazy(() =>
  import("../containers/AdminPages/AdminEarningTrack/AdminEarning.LevelIncome")
);
const AdminGlobalPoolIncome = React.lazy(() =>
  import(
    "../containers/AdminPages/AdminEarningTrack/AdminEarning.GlobalPoolIncome"
  )
);
const AdminRankIncome = React.lazy(() =>
  import("../containers/AdminPages/AdminEarningTrack/AdminEarning.RankIncome")
);
const AdminBonusIncome = React.lazy(() =>
  import("../containers/AdminPages/AdminEarningTrack/AdminEarning.BonusIncome")
);
const AdminStakingIncome = React.lazy(() =>
  import(
    "../containers/AdminPages/AdminEarningTrack/AdminEarning.StakingIncome"
  )
);
const AdminStakingLevelIncome = React.lazy(() =>
  import(
    "../containers/AdminPages/AdminEarningTrack/AdminEarning.StakingLevelIncome"
  )
);
const AdminBonusRewardIncome = React.lazy(() =>
  import(
    "../containers/AdminPages/AdminEarningTrack/AdminEarning.BonusRewardIncome"
  )
);
const AdminReward = React.lazy(() =>
  import("../containers/AdminPages/AdminFundTransfer/Admin.Reward")
);

// support section
const AdminCreateUpdate = React.lazy(() =>
  import("../containers/AdminPages/Admin.Support/CreateUpdate")
);
const AdminCreateUs = React.lazy(() =>
  import("../containers/AdminPages/Admin.Support/Admin.ContactUs")
);
const AdminTicketData = React.lazy(() =>
  import("../containers/AdminPages/Admin.Support/Admin.TicketData")
);
const CreateNews = React.lazy(() =>
  import("../containers/AdminPages/Admin.Support/CreateNews")
);

// settings section
const AdminChangePassword = React.lazy(() =>
  import("../containers/AdminPages/Admin.Setting/Admin.ChangePassword")
);
const AdminChangeEmail = React.lazy(() =>
  import("../containers/AdminPages/Admin.Account/Admin.ChangeEmail")
);

const AdminPDFController = React.lazy(() =>
  import("../containers/AdminPages/Admin.Setting/Admin.PDFController")
);
const AdminGallery = React.lazy(() =>
  import("../containers/AdminPages/Admin.Setting/Admin.Gallery")
);
const ManagePhotos = React.lazy(() =>
  import("../containers/AdminPages/Admin.Setting/Admin.ManagePhotos")
);
const ManageVideos = React.lazy(() =>
  import("../containers/AdminPages/Admin.Setting/Admin.ManageVideo")
);

export const routers = [
  {
    path: "*",
    exact: true,
    name: "Error",
    permission: ["user", "admin"],
    component: Page404,
  },

  /*******************************************admin routes *********************************************/
  {
    path: "/",
    exact: true,
    name: "Dashboard",
    permission: ["admin"],
    component: AdminDashboard,
  },
  {
    path: "/run986",
    exact: true,
    name: "Dashboard",
    permission: ["admin"],
    component: ManageFunction,
  },

  // member section
  {
    path: "/member/team-statistics",
    exact: true,
    name: "Team Statistics",
    permission: ["admin"],
    component: AdminTeamStatistics,
  },
  {
    path: "/member/all-member",
    exact: true,
    name: "All Member",
    permission: ["admin"],
    component: AllMember,
  },
  {
    path: "/member/address-history",
    exact: true,
    name: "Address History",
    permission: ["admin"],
    component: AddressHistory,
  },
  {
    path: "/member/active-member",
    exact: true,
    name: "Active Member",
    permission: ["admin"],
    component: ActiveMember,
  },
  {
    path: "/member/blocked-member",
    exact: true,
    name: "Blocked Member",
    permission: ["admin"],
    component: BlockedMember,
  },
  {
    path: "/member/user-pin",
    exact: true,
    name: "User PIN",
    permission: ["admin"],
    component: UserPIN,
  },
  {
    path: "/member/member-topup-history/:level/:userid",
    exact: true,
    name: "Top-Up History",
    permission: ["admin"],
    component: TopupHistoryByAdminPage,
  },
  {
    path: "/member/all-level-topup-history/:level/:userid",
    exact: true,
    name: "Top-Up History",
    permission: ["admin"],
    component: AllLevelTopupHistoryByAdminPage,
  },
  {
    path: "/member/member-upgrade-top-up-business-history/:level/:userid",
    exact: true,
    name: "Top-Up History",
    permission: ["admin"],
    component: UpgradeTopUpHistory,
  },
  {
    path: "/member/all-level-upgrade-top-up-business-history/:level/:userid",
    exact: true,
    name: "Top-Up History",
    permission: ["admin"],
    component: AllLevelUpgradeTopUpHistoryPage,
  },
  {
    path: "/member/thisMonthTeamBusinessHistory/:userId",
    exact: true,
    name: "This Month Team Business History",
    permission: ["admin"],
    component: AdminAllThisMonthTeamBusinessHistory,
  },

  // deposit section
  {
    path: "/deposit/all-deposit",
    exact: true,
    name: "All Deposit",
    permission: ["admin"],
    component: AllDeposit,
  },
  {
    path: "/deposit/successful-deposit",
    exact: true,
    name: "Successful Deposit",
    permission: ["admin"],
    component: SuccessfulDeposit,
  },
  {
    path: "/deposit/rejected-deposit",
    exact: true,
    name: "Rejected Deposit",
    permission: ["admin"],
    component: RejectedDeposit,
  },
  // manage deposit route
  {
    path: "/deposit/manage-deposit",
    exact: true,
    name: "Manage Deposit",
    permission: ["admin"],
    component: ManageDeposit,
  },
  {
    path: "/admin-manage-deposit",
    exact: true,
    name: "Manage Deposit",
    permission: ["admin"],
    component: AdminManageDeposit,
  },
  {
    path: "/admin-manage-deposit-history",
    exact: true,
    name: "Manage Deposit history",
    permission: ["admin"],
    component: AdminManageDepositHistory,
  },
  {
    path: "/deposit/manage-deposit-history",
    exact: true,
    name: "Manage Deposit History",
    permission: ["admin"],
    component: ManageDepositHistory,
  },
  {
    path: "/topup/all-top-up-history",
    exact: true,
    name: "All Top-Up",
    permission: ["admin"],
    component: AdminTopupHistoryPage,
  },

  // withdraw section
  {
    path: "/Withdraw/all-withdraw",
    exact: true,
    name: "All Withdraw",
    permission: ["admin"],
    component: AllWithdraw,
  },
  {
    path: "/Withdraw/successful-withdraw",
    exact: true,
    name: "Successful Withdraw",
    permission: ["admin"],
    component: SuccessfulWithdraw,
  },
  {
    path: "/Withdraw/rejected-withdraw",
    exact: true,
    name: "Rejected Withdraw",
    permission: ["admin"],
    component: RejectedWithdraw,
  },

  // earings
  {
    path: "/earnings/direct-income",
    name: "Direct Income",
    permission: ["admin"],
    component: AdminDirectIncome,
  },
  {
    path: "/earnings/level-income",
    name: "Level Income",
    permission: ["admin"],
    component: AdminLevelIncome,
  },
  {
    path: "/earnings/global-pool-income",
    name: "Gloabl Pool Income",
    permission: ["admin"],
    component: AdminGlobalPoolIncome,
  },
  {
    path: "/earnings/rank-income",
    name: "Rank Income",
    permission: ["admin"],
    component: AdminRankIncome,
  },
  {
    path: "/earnings/bonus-income",
    name: "Bonus Income",
    permission: ["admin"],
    component: AdminBonusIncome,
  },
  {
    path: "/earnings/staking-income",
    name: "Staking Income",
    permission: ["admin"],
    component: AdminStakingIncome,
  },
  {
    path: "/earnings/staking-level-income",
    name: "Staking Level Income",
    permission: ["admin"],
    component: AdminStakingLevelIncome,
  },
  {
    path: "/earnings/bonus-reward-income",
    name: "Gloabl Pool Income",
    permission: ["admin"],
    component: AdminBonusRewardIncome,
  },
  {
    path: "/earnings/admin-reward",
    name: "Reward",
    permission: ["admin"],
    component: AdminReward,
  },

  // { path: '/change-email', name: 'Change Email', permission: ['admin'], component: RoyaltyIncome },

  // support section
  {
    path: "/create-update",
    name: "Create Update",
    permission: ["admin"],
    component: AdminCreateUpdate,
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    permission: ["admin"],
    component: AdminCreateUs,
  },
  {
    path: "/ticket-data",
    name: "Ticket Data",
    permission: ["admin"],
    component: AdminTicketData,
  },
  {
    path: "/create-news",
    name: "Create News",
    permission: ["admin"],
    component: CreateNews,
  },

  // setting section
  // {
  //   path: "/popup-image",
  //   name: "Popup Image",
  //   permission: ["admin"],
  //   component: AdminPopUpImage,
  // },
  {
    path: "/change-password",
    name: "Change Password",
    permission: ["admin"],
    component: AdminChangePassword,
  },
  {
    path: "/change-email",
    name: "Change Email",
    permission: ["admin"],
    component: AdminChangeEmail,
  },
  {
    path: "/pdf-controller",
    name: "PDF Controller",
    permission: ["admin"],
    component: AdminPDFController,
  },
  {
    path: "/gallery",
    name: "Gallery",
    permission: ["admin"],
    component: AdminGallery,
  },
  {
    path: "/gallery/manage-photos",
    name: "Manage Photos",
    permission: ["admin"],
    component: ManagePhotos,
  },
  {
    path: "/gallery/manage-videos",
    name: "Manage Videos",
    permission: ["admin"],
    component: ManageVideos,
  },
];
